<div class="filter-search-container">
  <header class="search-header">
    <geaui-button-v2
      *ngIf="hasFilter"
      [type]="'primary'"
      class="btn-brdr-radius"
      preIcon="16px_system-preferences"
      (click)="showFilterSelection = !showFilterSelection"
    >
      Filter
    </geaui-button-v2>
    <mat-form-field
      class="table-header-search-inp"
      [ngClass]="hasFilter ? 'has-filter-btn' : ''"
    >
      <mat-label>{{ searchTextPlaceholder }}</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="searchModelField.Search"
        (ngModelChange)="searchChange($event)"
      />
      <mat-icon matSuffix class="search-icon">search</mat-icon>
    </mat-form-field>
  </header>
  <section
    #filterSelection
    class="filter-selection"
    [class.show]="showFilterSelection"
    *ngIf="hasFilter"
  >
    <div class="filter-field-list">
      <div
        *ngFor="let filter of filterRenderConfigurations; let i = index"
        class="filter-field"
      >
        <mat-label>{{ filter.name }}</mat-label>
        <mat-form-field
          class="form-select"
          *ngIf="filter.type == 'select' || filter.type == 'multiSelect'"
        >
          <mat-select
            #matRef
            [multiple]="filter.type == 'multiSelect'"
            [ngModel]="
              filter.type == 'select' && selectedFilters[filter.configName]
                ? selectedFilters[filter.configName][0]
                : selectedFilters[filter.configName]
            "
            (ngModelChange)="
              filter.type == 'select' && selectedFilters[filter.configName]
                ? (selectedFilters[filter.configName][0] = $event)
                : (selectedFilters[filter.configName] = $event)
            "
            [compareWith]="comparer"
            (selectionChange)="
              addToFilterList(
                filter.configName,
                filter.key,
                $event,
                filter.type
              )
            "
          >
            <mat-option
              *ngFor="let option of filter.options; let i = index"
              [value]="option"
              >{{ option.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form-select" *ngIf="filter.type == 'dateRange'">
          <!-- <mat-label>{{filter.placeholder}}</mat-label> -->
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              #dateRangeStart
              placeholder="Start date"
              [ngModel]="
                selectedFilters[filter.configName]
                  ? selectedFilters[filter.configName][0].key
                  : ''
              "
            />
            <input
              matEndDate
              #dateRangeEnd
              placeholder="End date"
              [ngModel]="
                selectedFilters[filter.configName]
                  ? selectedFilters[filter.configName][1].key
                  : ''
              "
              (dateChange)="
                dateRangeChanged(
                  filter.configName,
                  filter.key,
                  dateRangeStart,
                  dateRangeEnd
                )
              "
            />
          </mat-date-range-input>
          @if (!fieldInvalid[filter.configName]) {
          <mat-hint>{{ filter.dateFormat }} – {{ filter.dateFormat }}</mat-hint>
          } @else {
          <mat-hint style="color: red">Invalid date or range</mat-hint>
          }
          <mat-datepicker-toggle
            matSuffix
            class="filter-datepicker"
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field
          *ngIf="
            filter.type == 'autoSelect' || filter.type == 'autoMultiSelect'
          "
          class="form-select"
          (click)="autoFieldClicked(filter.configName, filter.key, filter.type)"
        >
          <mat-select
            [id]="filter.key"
            [multiple]="filter.type == 'autoMultiSelect'"
            [placeholder]="
              !selectedFilters[filter.configName] ? 'Select ' + filter.name : ''
            "
            panelClass="custom-mat-select"
            disableOptionCentering
            (closed)="onPanelClose(filter.configName)"
            [compareWith]="comparer"
            [ngModel]="
              filter.type == 'autoSelect' && selectedFilters[filter.configName]
                ? selectedFilters[filter.configName][0]
                : selectedFilters[filter.configName]
            "
            (ngModelChange)="
              filter.type == 'autoSelect' && selectedFilters[filter.configName]
                ? (selectedFilters[filter.configName][0] = $event)
                : (selectedFilters[filter.configName] = $event)
            "
            (selectionChange)="
              addToFilterList(
                filter.configName,
                filter.key,
                $event,
                filter.type
              )
            "
          >
            <input
              class="autocomplete-inp"
              [formControl]="autoselectFC"
              type="text"
              placeholder="Type to filter"
            />
            @if (autocompleteOptions[filter.configName] &&
            autocompleteOptions[filter.configName].length > 0) { @for (option of
            autocompleteOptions[filter.configName]; track option ; let idx =
            $index) {
            <mat-option
              [value]="option"
              [title]="option.label"
              [style.border-bottom]="
                matchedTerms != 0 && idx == matchedTerms - 1
                  ? '1px solid black'
                  : ''
              "
              >{{ option.label }}</mat-option
            >
            } } @else if (autocompleteOptions[filter.configName] &&
            autocompleteOptions[filter.configName].length == 0) {
            <mat-option disabled>Not found</mat-option>
            } @else {
            <mat-option class="fetching-options" disabled>
              <span style="margin-right: 10px">Fetching options</span>
              <mat-spinner [diameter]="20"></mat-spinner>
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="apply">
      <geaui-button-v2
        [disabled]="applyDisabled"
        [type]="'primary'"
        (click)="applyFilter()"
      >
        Apply
      </geaui-button-v2>
    </div>
  </section>
  <div
    class="sub-content"
    [class.filter-shown]="showFilterSelection"
    [class.has-filter]="hasFilter"
    [style.transform]="
      !showFilterSelection
        ? 'translateY(-' + filtersHeight + 'px)'
        : 'translateY(0)'
    "
  >
    <section class="applied-filters-list" *ngIf="filtersApplied.length > 0">
      <div class="filter-text">Filters Applied:</div>
      <mat-chip-list
        class="chip-list"
        #chipList
        aria-label="Product selection"
        *ngFor="let filter of filtersApplied"
      >
        <mat-chip class="geaui-chip">
          <button matChipRemove (click)="removeFilter(filter)">
            <mat-icon class="custom-svg">close</mat-icon>
          </button>
          <span>{{ filter.name }}: {{ filter.value }}</span>
        </mat-chip>
      </mat-chip-list>
      <a (click)="resetAllFilters()" class="reset-filter">
        <div>Reset All</div>
        <mat-icon class="custom-svg">replay</mat-icon>
      </a>
    </section>
    <section class="user-mat-table">
      <ng-content></ng-content>
    </section>
  </div>
</div>
