<div>

  <div class="title-div">
    <div class="user-title">
      <span class="heading-left">Users</span>
    </div>
    <div class="add-btn-container">
      <button
        mat-raised-button
        *ngIf="canEdit"
        (click)="new()"
        class="Button geaui-button-primary"
      >
        Add User
      </button>
    </div>
  </div>
  <br />

  <div>
    <table class="TableHeader">
      <tr>
        <td>
          <mat-form-field class="TableHeaderSearch">
            <mat-label style="color: var(--geaui-vibrant-blue)"
              >Search Users</mat-label
            >
            <input
            type="text"
            matInput
            [(ngModel)]="table.View.Search"
            (ngModelChange)="table.SearchUpdated()"
            />
            <mat-icon style="color: var(--geaui-vibrant-blue)" matSuffix
              >search</mat-icon
            >
          </mat-form-field>
        </td>
      </tr>
    </table>
  </div>
  <table
    mat-table
    [dataSource]="table.Result.Data"
    matSort
    matSortDisableClear
    [matSortActive]="table.View.SortColumn"
    (matSortChange)="table.RefreshData($event)"
  >
    <!-- <ng-container matColumnDef="Icon">
      <th mat-header-cell *matHeaderCellDef class="TableIconColumn"></th>
      <td mat-cell *matCellDef="let row">
        <mat-icon matSuffix>person</mat-icon>
      </td>
    </ng-container> -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.Name }}</td>
    </ng-container>
    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let row">{{ row.Email }}</td>
    </ng-container>
    <ng-container matColumnDef="City">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
      <td mat-cell *matCellDef="let row">{{ row.City }}</td>
    </ng-container>
    <ng-container matColumnDef="State">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
      <td mat-cell *matCellDef="let row">{{ row.State }}</td>
    </ng-container>
    <ng-container matColumnDef="Country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
      <td mat-cell *matCellDef="let row">{{ row.Country }}</td>
    </ng-container>
    <ng-container matColumnDef="OrderStatusFilters">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Order Status Filters
      </th>
      <td mat-cell *matCellDef="let row">{{ row.OrderStatusFilters }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row">
        <span (click)="edit(row)">
          <mat-icon class="ActionIcon" title="Edit" matSuffix><geaui-icon-v2
            file="16px_edit"
            [colorProp]="'stroke'"
          ></geaui-icon-v2></mat-icon>
        </span>
        <span (click)="delete(row)">
          <mat-icon class="ActionIcon" title="Delete" matSuffix><geaui-icon-v2
            file="16px_remove"
            [colorProp]="'stroke'"
          ></geaui-icon-v2></mat-icon>
        </span>
      </td>
    </ng-container>









    <!-- <ng-container matColumnDef="Actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="TableActionsColumn"
      >
        Actions
      </th>
      <td mat-cell *matCellDef="let row">
        <span class="ActionIcon" (click)="edit(row)">
          <mat-icon matSuffix>edit</mat-icon>
        </span>
        <span class="ActionIcon" (click)="delete(row)">
          <mat-icon matSuffix>delete</mat-icon>
        </span>
      </td>
    </ng-container> -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="table.Result.Count"
    [pageSizeOptions]="table.View.PageSizeOptions"
    [pageSize]="table.View.PageSize"
    [pageIndex]="table.View.Page"
    (page)="table.RefreshData($event)"
  >
  </mat-paginator>
</div>
