import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from "../../../environments/environment";

import { Users } from "./api/users";
import { Assets } from "./api/assets";
import { Projects } from "./api/projects";
import { Companies } from "./api/companies";
import { Sites } from "./api/sites";
import { Products } from './api/products';
import { Documents } from "./api/documents";
import { UserType } from '../data/user-types';
import { FeedbackApi } from './api/feedback';
import { BookmarkApi } from './api/bookmarks';
import { IOT } from './api/iot';
import { UserService } from '@gea/digital-ui-lib';
import { UserApplicationFeature } from '../data/data.generated';
import { ApplicationFeature } from '../data/application-feature';
import { Role } from '../data/roles';
import { Router } from '@angular/router';
import { sidebarNavigation } from 'src/app/sidebar-navigation';
import { Filters } from './api/filters';
import { ConfigApi } from './api/config';

@Injectable({
  providedIn: 'root', // Singleton
})
export class ApiService {
  constructor(private router: Router, private readonly _http: HttpClient, private userService: UserService) {
  }

  public Users = new Users(this);
  public Assets = new Assets(this);
  public Projects = new Projects(this);
  public Companies = new Companies(this);
  public Config = new ConfigApi(this);
  public Sites = new Sites(this);
  public Products = new Products(this);
  public Documents = new Documents(this);
  public Feedback = new FeedbackApi(this);
  public Bookmark = new BookmarkApi(this);
  public IOT = new IOT(this);
  public Filters = new Filters(this);

  public IsLoggedIn = false;
  public UserId = 0;
  public Email = "";
  public FullName = "";
  public GeaIdUserId = "";
  public AuthToken = "";
  public Role = Role.Guest;
  public AppFeatures: UserApplicationFeature[] = [];
  public LastFeatureGuard: ApplicationFeature = ApplicationFeature.Home;

  public CanViewFeature(feature: ApplicationFeature) {
    var settings = this.AppFeatures?.find(x => x.FeatureId == feature)
    return settings?.CanView ?? false;
  }

  public CanEditFeature(feature: ApplicationFeature) {
    var settings = this.AppFeatures?.find(x => x.FeatureId == feature)
    return settings?.CanEdit ?? false;
  }

  public LoadCachedFeatures() {
    if (this.AppFeatures.length == 0 && sessionStorage.getItem('AppFeatures'))
      this.AppFeatures = <UserApplicationFeature[]>JSON.parse(sessionStorage.getItem('AppFeatures') ?? "[]");
  }

  public CheckLastGuardStillValid() {
    if (!this.CanViewFeature(this.LastFeatureGuard))
      this.router.navigate(['/home']);
  }

  public StoreRedirectRoute()
  {
    const pieces = window.location.href.split(/[/]+/);
    const route = pieces[pieces.length - 1];
    if (!route.includes("#state=") && !route.startsWith("home") && route.length > 1) {
      sessionStorage.setItem("RedirectRoute", route);
    }
  }

  public CheckRedirectRoute()
  {
    if (sessionStorage.getItem("RedirectRoute")) {
      const pieces = window.location.href.split(/[/]+/);
      const currentRoute = pieces[pieces.length - 1];
      if (currentRoute == "home" || currentRoute.length == 0) {
        let cachedRoute = sessionStorage.getItem("RedirectRoute");
        let cachedBaseRoute = cachedRoute?.split(";")[0];
        if (sidebarNavigation.find(x => x.route == "/" + cachedBaseRoute)) {
          let routeInfo = sidebarNavigation.filter(x => x.route == "/" + cachedBaseRoute)[0];
          if (this.CanViewFeature(routeInfo?.feature)) {
            window.location.href = "/#/" + cachedRoute;
          }
        }
      }
      sessionStorage.removeItem("RedirectRoute");
    }
  }

  public Get<T>(url: string, paramsObject: any): Observable<T> {
    return this._http
      .get<T>(environment.endpoint + url, {
        headers: {
          Email: this.Email,
          FullName: this.FullName,
          GeaIdUserId: this.GeaIdUserId,
          AuthToken: this.AuthToken,
        },
        params: paramsObject
      });
  }

  public Post(url: string, paramsObject: any, files: File[] | undefined = undefined): Observable<any> {
    let formData: FormData = new FormData();
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append('File' + i.toString(), files[i], files[i].name);
      }
    }
    for (let param in paramsObject) {
      if (param == 'Filters') {
        formData.append(param, JSON.stringify(paramsObject[param]));
      } else {
        formData.append(param, paramsObject[param]);
      }
    }
    return this._http.post<string>(environment.endpoint + url, formData, {
      headers: {
        Email: this.Email,
        FullName: this.FullName,
        GeaIdUserId: this.GeaIdUserId,
        AuthToken: this.AuthToken,
      }
    });
  }

  public Download(url: string, paramsObject: any, filename: string, fileType: string, finished: (err: string) => void) {
    return this._http
      .get(environment.endpoint + url, {
        responseType: 'blob',
        headers: {
          Email: this.Email,
          FullName: this.FullName,
          GeaIdUserId: this.GeaIdUserId,
          AuthToken: this.AuthToken,
        },
        params: paramsObject
      })
      .subscribe(result => {
        result.arrayBuffer().then(x => {
          this.downloadFile(x, filename, fileType)
          finished("");
        });
      }, () => {
        finished("Error downloading file.");
      });
  }

  private downloadFile(data: ArrayBuffer, fileName: string, contentType: string) {
    var blob = new Blob([data], { type: contentType });
    var url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.href = url;
    if (fileName.toLowerCase().endsWith("pdf")||fileName.toLowerCase().endsWith("png")|| 
    fileName.toLowerCase().endsWith("jpg")||fileName.toLowerCase().endsWith("jpeg")) {
      a.target = '_blank'; // Open PDFs/images in new tab
    }
    else {
      a.download = fileName; // Download other file types normally
    }
    a.click();
    setTimeout(function revokeUrl() {
      window.URL.revokeObjectURL(url);
    }, 3600000);
  }

  public UploadFiles(files: File[], paramsObject: any) {
    let formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('File' + i.toString(), files[i], files[i].name);
    }
    this._http.post("UploadFiles", formData, {
      headers: {
        Email: this.Email,
        FullName: this.FullName,
        GeaIdUserId: this.GeaIdUserId,
        AuthToken: this.AuthToken,
      },
      params: paramsObject
    });
  }

  public runTest(params?: any[]): Observable<string> {
    return this.Get<string>("RunTest", params);
  }
}

