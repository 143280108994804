import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER, P } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from 'src/app/shared/services/api.service';
import { GeoChartOptions } from 'src/app/shared/components/geo-chart/geo-chart.component';
import { HrtTableComponent } from 'src/app/shared/components/hrt-table/hrt-table.component';
import { TableBasicExample } from 'src/app/shared/components/mat-table/hrt-mat-table.component';
import { TabData } from 'src/app/shared/components/hrt-tab/hrt-tab.component';
import {
  ApiService as ApiGeaUi, UserNotificationsApiService
} from '@gea/digital-ui-lib';
import { DatabricksStream } from 'src/app/shared/data/data.generated';

@Component({
selector: 'app-sandbox',
    templateUrl: './sandbox.component.html',
    styleUrls: ['./sandbox.component.scss'],
})
export class SandboxComponent implements OnInit {
    // Test properties
    public name: string = 'John Doe';
    public age: number = 35;
    public isOnline: boolean = true;
    public tabsData: TabData[] = [
        {
            tabName: 'HRT Table',
            tabComponent: HrtTableComponent,
            isTabVisible : true,
            data: {
                testData: 'HELLO'
            }
        },
        {
            tabName: 'Mat Table',
            tabComponent: TableBasicExample,
            isTabVisible : true,
        }
    ]
    public products: string[] = [
        'Screw',
        'Piston',
        'Chiller',
        'Control Panel',
        'Heat Exchanger',
    ];
    public selected: string = '';
    public paragraph: string =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis diam, quis maximus elit porta eget. Nullam dolor ligula, efficitur in suscipit vel, dapibus id felis. Ut est risus, sagittis a ex vel, fermentum posuere augue. Ut in vehicula purus. Praesent vehicula, lacus et imperdiet volutpat, tellus dolor condimentum ipsum, a hendrerit nisi justo sed arcu. Pellentesque mattis libero quis justo rutrum sollicitudin. Vestibulum ut tempus libero. Mauris gravida ante egestas, vestibulum nunc ut, malesuada turpis. Sed vitae cursus velit. Praesent scelerisque arcu eros, sit amet tempor nulla maximus eget. Nulla sapien orci, dignissim eget lorem vitae, mollis efficitur ipsum. Maecenas lectus dui, varius nec eros eget, ullamcorper faucibus eros. Etiam eu ex massa. Curabitur dapibus libero enim, vel vehicula risus congue vitae. Maecenas ac quam sed libero maximus dapibus.';
    public html: string = '';
    public files: File[] = [];
    public popup: boolean = false;
    public myControl = new FormControl('');
    public filteredOptions: Observable<string[]> | undefined = undefined;
    public filteredOptions2: Observable<string[]> | undefined = undefined;
    public selectedProducts: string[] = ['Screw'];
    public chipCtrl = new FormControl('');
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    @ViewChild('productInput') productInput:
        | ElementRef<HTMLInputElement>
        | undefined;
    public range = new FormGroup({
        start: new FormControl(new Date(2022, 11, 13)),
        end: new FormControl(new Date(2022, 11, 16)),
    });
    public gallery = [
        '../assets/dashboard/BluAstrum.png',
        '../assets/dashboard/BluRedFusion.png',
        '../assets/dashboard/Compax.png',
    ];
    public cached: string = '';
    
    public tasks: any[] = [{
        id: 1,
        parentId: 0,
        title: 'Software Development',
        start: new Date('2019-02-21T05:00:00.000Z'),
        end: new Date('2019-07-04T12:00:00.000Z')
    }, {
        id: 2,
        title: 'Scope',
        start: new Date('2019-02-21T05:00:00.000Z'),
        end: new Date('2019-02-26T09:00:00.000Z')
    }, {
        id: 3,
        title: 'Determine project scope',
        start: new Date('2019-02-21T05:00:00.000Z'),
        end: new Date('2019-02-21T09:00:00.000Z')
        }];
    
    worldOptions: GeoChartOptions = GeoChartOptions.WorldViewOptions;
    stateOptions: GeoChartOptions = GeoChartOptions.StateViewOptions;
    worldData: (string | number)[][] = [["US", 10], ["CA", 20]];
    stateData: (string | number)[][] = [["PA", 10], ["NY", 20], ["CA", 30]];

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private authService: MsalService,
      private api: ApiService,
      private apiGeaUi: ApiGeaUi,
      private notification: UserNotificationsApiService
    ) { }

    public ngOnInit(): void {
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
        );
        this.filteredOptions2 = this.chipCtrl.valueChanges.pipe(
            startWith(null),
            map((fruit: string | null) =>
                fruit ? this._filter(fruit) : this.products.slice()
            )
        );
        this.cached = localStorage.getItem('cached') ?? '';
    }

    public log(msg: string) {
        console.log(msg);
    }

    public square(val: number) {
        return val * val;
    }

    public showAlert(): void {
        alert('Test alert');
    }

    public getArray(items: number) {
        return Array(items);
    }

    public openDialog() {
        this.dialog.open(DialogExample);
    }

    callDatabricks() {
        // let omniIds = "7565,7566,7567,7568,7380,7526,6158,6319,6928,11386";
        let omniIds = "7565";
        let isChartData = true;
        const start = Date.now();
        this.api.IOT.GetDeviceInformationFromDbs(omniIds, true, 'discharge_Pressure').subscribe({
            next: (x: DatabricksStream) => {
                console.log('Time taken:', Date.now() - start);
                console.log('Resp:', x);
                if (x.status.state == 'SUCCEEDED') {
                    let jsonResp;
                    if (isChartData) {
                        jsonResp = this.api.IOT.processDbsChartData(x, 'discharge_Pressure');
                    } else {
                        if (omniIds.split(',').length > 1) {
                            jsonResp = this.api.IOT.processDbsStreamForMultiDevice(x);
                        } else {
                            jsonResp = this.api.IOT.processDbsStreamForSingleDevice(x); 
                        }
                    }
                    console.log(jsonResp);
                } else {
                    console.log('Error:', x.status.error?.message);
                }
              },
              error: (error) => {
                console.log(error);
              },
        })
    }

    public addProduct(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        // Add our fruit
        if (value) {
            this.selectedProducts.push(value);
        }

        // Clear the input value
        event.chipInput!.clear();

        this.chipCtrl.setValue(null);
    }

    public removeProduct(fruit: string): void {
        const index = this.selectedProducts.indexOf(fruit);

        if (index >= 0) {
            this.selectedProducts.splice(index, 1);
        }
    }

    public selectedProduct(event: MatAutocompleteSelectedEvent): void {
        this.selectedProducts.push(event.option.viewValue);
        if (this.productInput) this.productInput.nativeElement.value = '';
        this.chipCtrl.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.products.filter((option) =>
            option.toLowerCase().includes(filterValue)
        );
    }

    public showSnackbar() {
        this.snackBar.open('Test', undefined, {
            duration: 5000,
        });
    }
    public upload() {
        let test = this.files;
        this.api.runTest([{ file: test[0] }]).subscribe((x) => {
            console.log(x);
        });
    }

    public cache() {
        localStorage.setItem('cached', this.cached);
    }

    public login() {
        this.authService.loginPopup().subscribe({
            next: (result) => {
                console.log(JSON.stringify(result));
                alert(
                    'Email:' + result.account?.username + ' | ' +
                    'Name:' + result.account?.name + ' | ' +
                    'Token:' + result.idToken + ' | ' +
                    ''
                );
            },
            error: (error) => console.log(error),
        });
    }

    public testToken() {
        this.api.runTest().subscribe((x) => {
            console.log(x);
        });
    }

    public sfsd(abc: string) {

  }
  
  public sendNotification() {
    const today = new Date();
    const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDay());

    const notification: SendNotificationDto = {
      orgaId: [],
      userIds: [ this.api.GeaIdUserId ],
      roleIds: [],
      notificationType: NotificationType.USER_CONTACT,
      createdAt: today,
      validFrom: today,
      validTo: nextYear,
      detailLink: '',
      templateReplacements: [
        {
          key: 'subject',
          value: 'sandbox test',
          type: TemplateReplacementType.PLAIN_TEXT,
        },
        {
          key: 'message',
          value: 'sandbox test - message here',
          type: TemplateReplacementType.PLAIN_TEXT,
        },
      ],
    };

    this.apiGeaUi.post<void>(this.notification.url, notification, {}, this.notification.apiVersion)
      .subscribe(x => {
        alert("send");
      });
  }
}

export interface UserNotification {
  id: string; // uuid
  app: string;
  notificationType: string;
  status: 'UNREAD' | 'READ' | 'DELETED';
  title: string;
  description: string;
  detailLink: string;
  createdAt: string; // "format": "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
}

export interface SendNotificationDto {
  orgaId: string[];
  userIds: string[];
  roleIds: string[];
  notificationType: NotificationType;
  createdAt: Date;
  validFrom: Date;
  validTo: Date;
  detailLink: string;
  templateReplacements: {
    key: string;
    value: string;
    type: TemplateReplacementType;
  }[];
}

export enum TemplateReplacementType {
  PLAIN_TEXT = 'PLAIN_TEXT',
}

export enum NotificationType {
  USER_CONTACT = 'USER_CONTACT',
}


@Component({
    selector: 'dialog-example',
    templateUrl: 'dialog-example.html',
})
export class DialogExample {
    constructor(public dialogRef: MatDialogRef<DialogExample>) { }
}
