import { Environment as EnvironmentEnum } from "@gea/digital-ui-lib";

import { Environment } from "./model";
export const environment: Environment = {
    name: EnvironmentEnum.UAT,
    production: true,
    endpoint: "https://hrtconnectuat.azurewebsites.net/api/",
    oAuth: {
        clientId: '17c6429e-7b00-474b-a45c-bd29a14f441f',
        authority: 'https://login.portal.uat.gea.com/geaiduat.onmicrosoft.com/b2c_1_signin_signup/',
        authorityDomain: 'login.portal.uat.gea.com',
        postLogoutRedirectURL: 'https://hrtconnect.apps.uat.gea.com/',
        redirectURL: 'https://hrtconnect.apps.uat.gea.com/',
    },
    baseURL: 'https://apim-gea-uat.azure-api.net/',
    interceptorURLs: ['https://apim-gea-uat.azure-api.net/', 'https://hrtconnect.apps.uat.gea.com/'],
    storageURL: 'https://strgaccb2cuat.blob.core.windows.net/strg-container-b2-uat/',
    localizationStorageURL: 'https://strgacci18nprod.blob.core.windows.net/strg-container-i18n-prod/',
    portal: {
        baseURL: 'https://hrtconnect.apps.uat.gea.com/',
        redirectURL: 'https://apim-gea-uat.azure-api.net/',
    }
};
