<geaui-page-layout
  [headerTemplate]="header"
  [contentTemplate]="content"
  [sidebarTemplate]="sidebar"
  [footerTemplate]="footer"
>
  <ng-template #header>
    <div class="header">
      <geaui-header
        [noAuth]="false"
        [navBarDefaultSize]="navbarMode"
        [headerTitle]="title"
      ></geaui-header>
    </div>
  </ng-template>
  <ng-template #content>
    <hrt-loader
      [setLoader]="!(tokenReady && userReady) || showLoadingIcon"
      [loadingMessage]="loadingMessage"
    ></hrt-loader>
    <div
      class="content-body"
      [style.width]="'calc(' + setContentWidth() + ')'"
      *ngIf="tokenReady && userReady && configReady"
    >
      <router-outlet></router-outlet>
    </div>
  </ng-template>
  <ng-template #sidebar>
    @if (tokenReady && userReady) {
    <geaui-sidebar-v2
      [devisionIconPath]="'assets/icons-v2/apps/hrtconnect-app_40px_blue.svg'"
      [devisionName]="'HRT Connect'"
      [navigationItems]="items"
      [bottomNavigation]="navItems"
      [hasSearch]="false"
      [slimView]="true"
    ></geaui-sidebar-v2>
    }
  </ng-template>

  <ng-template #footer>
    @if (tokenReady && userReady) {
    <div class="footer">
      <geaui-footer
        [copyright]="'GEA Heating & Refrigeration Technologies ' + year"
        [hasCookies]="false"
        (openDataprivacy)="openDataprivacy()"
        (openTermsAndConditions)="openTermsAndConditions()"
        (openImprint)="openImprint()"
      ></geaui-footer>
    </div>
    }
  </ng-template>
</geaui-page-layout>
