
<hrt-tab [hasTabs]="false" [title]="'Parts'"></hrt-tab>
<table class="TableHeader">
  <tr>
    <td>
      <mat-form-field appearance="standard" class="TableHeaderSearch">
        <mat-label style="color: var(--geaui-vibrant-blue);">Search Parts</mat-label>
        <input type="text" matInput [(ngModel)]="table.View.Search" (ngModelChange)="searchChange($event)">
        <mat-icon style="color: var(--geaui-vibrant-blue);" matSuffix>search</mat-icon>
      </mat-form-field>
    </td>
  </tr>
</table>
<table mat-table [dataSource]="table.Result.Data" 
        matSort matSortDisableClear
        [matSortActive]="table.View.SortColumn" 
        (matSortChange)="table.RefreshData($event)">
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">{{row.Name}}</td>
  </ng-container>
  <ng-container matColumnDef="Number">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
    <td mat-cell *matCellDef="let row">{{row.Number}}</td>
  </ng-container>
  <ng-container matColumnDef="Description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
    <td mat-cell *matCellDef="let row">{{row.Description}}</td>
  </ng-container>
  <ng-container matColumnDef="Category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
    <td mat-cell *matCellDef="let row">{{row.PoolName}}</td>
  </ng-container>
  <ng-container matColumnDef="Warehouse">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Warehouse</th>
    <td mat-cell *matCellDef="let row">{{row.Warehouse}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator 
  [length]="table.Result.Count" 
  [pageSizeOptions]="table.View.PageSizeOptions" 
  [pageSize]="table.View.PageSize" 
  [pageIndex]="table.View.Page" 
  (page)="table.RefreshData($event)">
</mat-paginator>
  