<table>
    <tr style="vertical-align: top;">
        <!-- Header -->
        <td style="width: 60px">
            <img src="../assets/product-icons/{{ setup.Icon }}" style="width: 45px">
        </td>
        <td colspan="3">
            <h2 style="display: inline-block; margin: 15px;">{{ setup.Title }}</h2>
            <img src="../assets/product-icons/Information.svg" class="HeaderButton"
                matTooltip="Info" matTooltipPosition="above"
                (click)="showInfo = true;">
            <img src="../assets/product-icons/AssetBank.svg" class="HeaderButton"
                matTooltip="Asset Bank" matTooltipPosition="above"
                (click)="showAssetBank = true;">
            <img src="../assets/product-icons/Warning.svg" class="HeaderButton"
                matTooltip="Warnings" matTooltipPosition="above"
                (click)="showWarnings = true;">
            <img src="../assets/product-icons/Error.svg" class="HeaderButton"
                matTooltip="Errors" matTooltipPosition="above"
                (click)="showErrors = true;">
            <img src="../assets/product-icons/Documents.svg" class="HeaderButton"
                matTooltip="Reports" matTooltipPosition="above"
                (click)="showReports = true;">
            <img src="../assets/product-icons/TotalCostOfOwnership.svg" class="HeaderButton"
                matTooltip="Total Cost of Ownership" matTooltipPosition="above"
                (click)="showTco = true;">
            <img src="../assets/product-icons/Pricing.svg" class="HeaderButton"
                matTooltip="Pricing" matTooltipPosition="above"
                (click)="showPricing = true;">
            <b>15,460.32 USD</b>
        </td>
    </tr>
    <tr>
        <!-- Tabs -->
        <td colspan="2">
            <div style="min-height: 50px;">
            </div>
        </td>

        <!-- Calc Button -->
        <td>

        </td>

        <!-- Image -->
        <td rowspan="3" style="vertical-align: top;">
            <img style="width: 500px;" src="../assets/product-images/{{ setup.Image }}" />
        </td>
    </tr>
    <tr>
        <!-- Tab Content -->
        <td colspan="2" style="vertical-align: top;">
            <app-intellisoc-general *ngIf="currentProductType == ProductType.IntelliSocRetro"></app-intellisoc-general>
            <app-oil-separator-calc *ngIf="currentProductType == ProductType.OilSeparator"></app-oil-separator-calc>
        </td>

    </tr>
</table>

<dx-popup [width]="900" [height]="700" [showTitle]="false" title="Pricing" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="showPricing">
    <div *dxTemplate="let data of 'content'">
        <img src="../assets/product-icons/Pricing.svg">
        <h2 style="display: inline-block; margin: 15px">Pricing</h2>
        <product-pricing [ProductId]="1"></product-pricing>
    </div>
</dx-popup>

<dx-popup [width]="900" [height]="700" [showTitle]="false" title="Information" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="showInfo">
    <div *dxTemplate="let data of 'content'">
        <product-info [ProductId]="1"></product-info>
    </div>
</dx-popup>
