<hrt-tab
  [hasTabs]="false"
  [title]="'Site Details'"
  [hasBookmark]="true" [bookmarkData]="isBookmark" (bookmarkToggled)="toggleBookmark($event)"
  [renderAllTabs]="false" [hasShareButton]="true"
></hrt-tab>
<div class="grid-container">
  <div class="grid-content" *ngFor="let element of siteData">
    <div class="grid-item-name">{{ element.name }}</div>
    <div class="grid-item-value">{{ element.value }}</div>
  </div>
</div>
<mat-divider class="divider"></mat-divider>

<div>
  <h1 class="sitedetails">
    <geaui-icon-v2
      class="geaui-hrt-icon"
      file="16px_playlist"
      [color]="'black'"
      [colorProp]="'stroke'"
    ></geaui-icon-v2>
    Site Assets
  </h1>
</div>
@if (isEnvironment == 'local') {

<hrt-table
  [tableId]="tableId"
  [columnDefinitions]="columnDefinitions"
  [defaultTableSettings]="defaultTableConfig"
  [isLoading]="true"
  [tableData]="tableData"
  [totalCount]="totalCount"
  (searchText)="updateSearchTerm($event)"
  (rowClicked)="rowClicked($event)"
  (tableSettings)="handleTableSettings($event)"
>
</hrt-table>

} @else {
<div>
  <gea-asset-table
    [assetTable]="table"
    [showMotorStatus]="true"
  ></gea-asset-table>
</div>

}
