
<div class="mainpage" background="black">
    <mat-tab-group selectedIndex="0">
        <mat-tab label="Heros">
            <div class="content">
                <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">
                        <mat-card class="mat-elevation-z4">
                            <mat-card-header>
                                <mat-card-title>Batman</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image alt="Batman image"  src="..\assets\layout\batman.jpg">
                            <mat-card-content>
                                <p>Gothem's dark knight.</p>
                            </mat-card-content>
                        <mat-card-actions>
                            <button  mat-raised-button color="primary"(click)="likesCount()">LIKE</button>
                            <button mat-button (click)="shareLink()">SHARE</button>
                        </mat-card-actions>         
                        </mat-card>
                        <mat-card class="mat-elevation-z4">
                            <mat-card-header>
                                <mat-card-title>James Gordan </mat-card-title>
                            </mat-card-header>
                            <img mat-card-image alt="Gordan image" src="..\assets\layout\gordantest.jfif">
                            <mat-card-content>
                                <p>Gothem's public justice.</p>
                                <p>Set to work non stop 180985935</p>
                            </mat-card-content>
                        <mat-card-actions>
                            <button mat-raised-button color="primary">LIKE</button>
                            <button mat-button>SHARE</button>
                        </mat-card-actions>  
                        </mat-card>   
                        <mat-card class="mat-elevation-z4">
                            <mat-card-header>
                                <mat-card-title>Harvey Dent</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image alt="Harvey Dent image"  src="..\assets\layout\harveydenttest.jfif">
                            <mat-card-content>
                                <p>Gothem's White knight.</p>
                                <p>The main hero of Gothem city, to bring peace and safety to all. Later corrupted by the jokers perspective of an unfair world.</p>
                            </mat-card-content>
                        <mat-card-actions>
                            <button mat-raised-button color="primary">LIKE</button>
                            <button mat-button>SHARE</button>
                        </mat-card-actions>   
                        </mat-card>         
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Villans">
            <div class="content">
                <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">
                        <mat-card class="mat-elevation-z4" >
                            <mat-card-header>
                                <mat-card-title>Joker</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image alt="Joker image" src="..\assets\layout\jokertest.jfif">
                            <mat-card-content>
                                <p>Why so Serious? -The Dark Knight.</p>
                                <p>Gothem's last laugh.</p>
                            </mat-card-content>
                        <mat-card-actions>
                            <button mat-raised-button color="primary">LIKE</button>
                            <button mat-button>SHARE</button>
                        </mat-card-actions>         
                        </mat-card>
                        <mat-card class="mat-elevation-z4">
                            <mat-card-header>
                                <mat-card-title>Ra's Al Ghul</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image alt="Ra's Al Ghul image" height="25%" src="..\assets\layout\rasalghultest.jfif">
                            <mat-card-content>
                                <p>The leader of an ancient secret society who's main goal is to destroy Gothem to bring balance.</p>
                                <p>Credited to train Batman to become strong in the shadows, over coming his fear of the dark.</p>
                            </mat-card-content>
                        <mat-card-actions>
                            <button mat-raised-button color="primary">LIKE</button>
                            <button mat-button>SHARE</button>
                        </mat-card-actions>         
                        </mat-card>     
                        <mat-card class="mat-elevation-z4">
                            <mat-card-header>
                                <mat-card-title>Harvey Dent</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image alt="Harvey Dent image"  src="..\assets\layout\baddenttest.jfif">
                            <mat-card-content>
                                <p>Gothem's Former White knight.</p>
                                <p>The fallen hero of Gothem city, corrupted by the jokers perspective of an unfair world.</p>
                                <p>His new belief is to create fairness in Gothem by only using a flip of a coin.</p>
                            </mat-card-content>
                        <mat-card-actions>
                            <button mat-raised-button color="primary">LIKE</button>
                            <button mat-button>SHARE</button>
                        </mat-card-actions>   
                        </mat-card>         
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Stats">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Batman</mat-panel-title>
                    <mat-panel-description>Super Hero</mat-panel-description>
                </mat-expansion-panel-header>
                    <div>
                        <p>
                            Originally named the  "Bat-Man," the character is also referred to by such epithets as the Caped Crusader, the Dark Knight, and the World's Greatest Detective.
                        </p>        
                        <mat-grid-list cols="2" style="width: 200px; height: 30px;" rowHeight="2: 1" gutterSize="5">
                            <mat-grid-tile colspan="2">Super Power: Money</mat-grid-tile>
                            <mat-grid-tile rowspan="2">Health: 42hp</mat-grid-tile>
                            <mat-grid-tile>Defense: 60</mat-grid-tile>
                            <mat-grid-tile>IQ: 192</mat-grid-tile>
                        </mat-grid-list>                                    
                        <div>{{"Likes: " + likesnumber}}</div>               
                    </div>               
            </mat-expansion-panel>
        </mat-tab>
        <mat-tab label="Calculator">
                <div class="jumbotron col-sm-4 p-2 m-0 bg-inverse">
                
                    <h1 class="text-center">Angular Calculator</h1>
                
                    //Displays the User Input
                    <div class="input-group input-group-sm col-sm-12 m-0 p-0">
                    <div class="col-sm-12 form-control text-lg-right" type="text">{{input}}</div>
                    </div>
                
                    //Displays the Results
                    <div class="input-group input-group-sm col-sm-12 m-0 p-0">
                    <div class="form-control text-sm-right" type="text">{{result}}</div>
                    </div>
                
                    <div class="col-sm-12 p-1 m-0">
                    <button class="btn btn-info col-sm-6" type="button" (click)="allClear()">C</button>
                    <button class="btn btn-warning col-sm-3" type="button" (click)="clear()">x</button>
                    <button class="btn btn-secondary col-sm-3" type="button" (click)="pressOperator('/')">/</button>
                    </div>
                
                    <div class="col-sm-12 p-1 m-0">
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('7')">7</button>
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('8')">8</button>
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('9')">9</button>
                    <button class="btn btn-lg btn-secondary col-sm-3 p-1" type="button" (click)="pressOperator('*')">X</button>
                    </div>
                    <div class="col-sm-12 p-1 m-0">
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('4')">4</button>
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('5')">5</button>
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('6')">6</button>
                    <button class="btn btn-lg btn-secondary col-sm-3 p-1" type="button" (click)="pressOperator('-')">-</button>
                    </div>
                    <div class="col-sm-12 p-1 m-0">
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('1')">1</button>
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('2')">2</button>
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('3')">3</button>
                    <button class="btn btn-lg btn-secondary col-sm-3 p-1" type="button" (click)="pressOperator('+')">+</button>
                    </div>
                    <div class="col-sm-12 p-1 m-0">
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('.')">.</button>
                    <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('0')">0</button>
                    <button class="btn btn-lg btn-success col-sm-6 p-1" type="button" (click)="getAnswer()">=</button>
                    </div>
                </div>            
                <table>
                    <tr>
                        <td>..</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                    </tr>
                    <tr>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                    </tr>
                    <tr>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                    </tr>
                    <tr>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                    </tr>
                    <tr>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                        <td>.</td>
                    </tr>
                </table>
            </mat-tab>
            <mat-tab>
                <div>
                    <mat-form-field appearance="legacy"> <!-- floatLabel hideRequiredMarker -->
                        <mat-label>Name</mat-label>
                        <input matInput required [(ngModel)]="name" />
                        <mat-hint align="end">Min length 5</mat-hint>
                    </mat-form-field>
            
                    <mat-form-field appearance="fill" color="warn">
                        <mat-label>Fill</mat-label>
                        <input matInput />
                    </mat-form-field>
            
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>Outline</mat-label>
                        <input matInput />
                    </mat-form-field>
                </div>
            </mat-tab>
    </mat-tab-group>
</div>
