import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Asset, AssetServiceRecord } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  Table,
  TableResult,
  TableView,
} from 'src/app/shared/services/api/table';
import { ImagesService } from 'src/app/shared/services/images.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { GetConditionImage } from 'src/app/shared/data/service-conditions';
import { ServiceTypes } from 'src/app/shared/data/service-types';
import { ApplicationFeature } from 'src/app/shared/data/application-feature';
import { HRTColumnDefinition } from 'src/app/shared/data/filterConfiguration';
import { tap } from 'rxjs';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ProductType } from 'src/app/shared/data/product-types';
import { environment } from 'src/environments';
import { Router } from '@angular/router';
import { tableDefinitions } from 'src/app/shared/data/table-definitions';
import { TableIconComponent } from 'src/app/shared/components/table-icon/table-icon.component';
import { HrtTableService } from 'src/app/shared/services/hrt-table.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'asset-service-data',
  templateUrl: './asset-service-data.component.html',
  styleUrls: ['./asset-service-data.component.scss'],
})
export class AssetServiceDataComponent implements OnInit {
  tableId: string = 'assetServiceDataComponent';
  totalCount: number = 0;
  columnDefinitions: HRTColumnDefinition[];
  tableData: any[] = [];
  listOptions: TableView = new TableView();
  assetServiceTable: Table<AssetServiceRecord> =
    new Table<AssetServiceRecord>();

  tableDataLoading: boolean = false;
  initialLoad: boolean = true;
  public isEnvironment: string | null = environment.name;

  @Input() public assetId: number = -1;
  public compressorId: number = -1;

  public isInternal: boolean = false;

  public displayedColumns: string[] = [
    'Date',
    'Type',
    'Condition',
    'C1',
    'C2',
    'C3',
    'C4',
    'M1',
    'M2',
    'Technician',
    'Notes',
    'Report',
  ];
  public table: Table<AssetServiceRecord> = new Table<AssetServiceRecord>();

  public notesDialog: boolean = false;
  public notesItem: AssetServiceRecord = new AssetServiceRecord();

  public pdfImage: string = this.images.Icons.FormatPdf;
  public infoImage: string = this.images.Icons.Information;
  public editImage: string = this.images.Icons.Edit;
  public deleteImage: string = this.images.Icons.Delete;

  constructor(
    private readonly api: ApiService,
    private images: ImagesService,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private hrtTableService: HrtTableService,
    private utilityService: UtilityService,
    private router: Router,
  ) {}


  ngOnInit(): void {
    this.assignMockColDefinitions();
    this.isInternal = this.api.CanEditFeature(ApplicationFeature.Assets);
    if (this.isInternal) {
      this.displayedColumns.push('Options');
    }

    if (sessionStorage.getItem(this.tableId + '-listOptions')) {
      this.listOptions = JSON.parse(
        sessionStorage.getItem(this.tableId + '-listOptions') || ''
      );
    }

    this.assetServiceTable.GetApi = (listOptions: TableView) => {
      if(this.compressorId == null){
        return this.api.Assets.GetAssetServiceRecords(
          this.listOptions,
          -1
        )
      }else{
        return this.api.Assets.GetAssetServiceRecords(
          this.listOptions,
          this.compressorId
        ).pipe(
          tap((assetServiceData: TableResult<AssetServiceRecord>) => {
            this.tableData = this.modifyTableData(assetServiceData.Data);
            this.table.Result.Data = assetServiceData.Data;
          })
        );
      }
      
    };

    this.assetServiceTable.Updated = () => (this.tableDataLoading = false);

    this.api.Assets.GetAssetDetails(this.assetId).subscribe((x) => {
      this.compressorId =
        <ProductType>x.Asset.ProductTypeId == ProductType.Omni
          ? x.Asset.ParentAssetId
          : this.assetId;
      // For initial load, control doesn't go in tap unless subscribed.
      this.assetServiceTable.refreshDataForTable()?.subscribe();
    });
    // Events from table-icon [digihub tables].
    this.hrtTableService.iconEvent.subscribe((event) => {
      if (event) {
        if (event.eventKey == 'assetServiceEdit') {
          this.edit(event.eventVal);
        }
        if (event.eventKey == 'assetServiceDelete') {
          this.delete(event.eventVal);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.initialLoad = false;
  }

  modifyTableData(assetServiceData: AssetServiceRecord[]) {
    const modifiedAsset = assetServiceData.map((asset: AssetServiceRecord) => {
      return {
        ...asset,
        TypeIdText: this.getTypeDescription(asset.TypeId),
      };
    });
    return modifiedAsset;
  }

  assignMockColDefinitions() {
    let definitions = tableDefinitions.assetServiceTableDef;

    this.columnDefinitions = this.addTypeIconToColumn(
      definitions,
      [
        'showNotes',
        'ReportLocation',
        'ConditionId',
        'C1Id',
        'C2Id',
        'C3Id',
        'C4Id',
        'M1Id',
        'M2Id',
        'actions',
      ],
      TableIconComponent
    );
  }

  addTypeIconToColumn(
    columnDef: HRTColumnDefinition[],
    keys: string[],
    component: any,
    data?: any
  ) {
    keys.forEach((key) => {
      const colIndex = columnDef.findIndex((def) => def.key === key);
      const rendererObj = {
        component: component,
        config: {
          field: key,
          data: data,
        },
      };
      columnDef[colIndex]['renderer'] = rendererObj;
    });
    return columnDef;
  }

  handleTableSettings(tableSettings: FilterTableSettings) {
    if (this.initialLoad) return;

    const oldOptions = JSON.parse(JSON.stringify(this.listOptions));

    const tableColKeys: string[] = Object.keys(tableSettings.columns);
    let sortKey = '';
    let sortDes = 'False';
    if (tableColKeys.length > 0) {
      // sorting exists
      for (let key in tableSettings.columns) {
        let sortValue = tableSettings.columns[key].sort;
        if (sortValue === 'asc' || sortValue === 'desc') {
          sortKey = key;
          sortDes = sortValue === 'desc' ? 'True' : 'False';
          break;
        }
      }
      this.assetServiceTable.View.SortColumn = sortKey;
      this.assetServiceTable.View.SortDesc = sortDes;

      this.listOptions.SortColumn = sortKey;
      this.listOptions.SortDesc = sortDes;
    }
    this.assetServiceTable.View.Page = tableSettings.page;
    this.listOptions.Page = tableSettings.page;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);

    // Object comparision as tableSettings sends multiple events.
    if (this.utilityService.deepEqual(oldOptions, this.listOptions)) return;
    this.assetServiceTable.SearchUpdated();
  }

  updateSearchTerm(searchText: string) {
    if (this.initialLoad) return;
    this.assetServiceTable.View.Search = searchText;
    this.listOptions.Search = searchText;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
    this.assetServiceTable.SearchUpdated();
  }

  getTypeDescription(id: number) {
    return ServiceTypes.find((x) => x.Id == id)?.Name ?? '';
  }

  getConditionImage(id: number) {
    return GetConditionImage(id, this.images);
  }

  formatDate(dateStr: string) {
    return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  trimNotes(notes: string) {
    if (notes.length > 50) return notes.substring(0, 50) + '...';
    return notes;
  }

  showNotes(item: AssetServiceRecord) {
    this.notesItem = item;
    this.notesDialog = true;
  }

  downloadFile(filename: string) {
    window.open(
      'https://hrt-fesportal-hgamdfadh9dyb3cv.westeurope-01.azurewebsites.net/RepairReports/' + filename
    );
  }

  downloadStorageReport(serviceRecord: AssetServiceRecord) {
    this.api.Assets.DownloadAssetServiceRecordReport(serviceRecord);
  }

  edit(serviceRecord: AssetServiceRecord | undefined = undefined) {
    let mode = 'add';
    if (serviceRecord != undefined) mode = 'edit';
    this.router.navigate([
      'service-record',
      {
        mode: mode,
        assetId: this.assetId,
        serviceRecordId: serviceRecord?.Id ?? 0,
      },
    ]);
  }

  delete(serviceRecord: AssetServiceRecord) {
    if (confirm('Are you sure you want to delete?')) {
      this.api.Assets.DeleteAssetServiceRecord(serviceRecord.Id).subscribe(
        (x) => {
          this.table.RefreshData();
        }
      );
    }
  }

  fixUrl(filename: string) {
    return encodeURIComponent(filename);
  }

  ngOnDestroy() {
    this.hrtTableService.iconEvent.next(undefined);
    sessionStorage.setItem(
      this.tableId + '-listOptions',
      JSON.stringify(this.listOptions)
    );
  }
}

@Component({
  selector: 'open-record',
  styleUrls: ['./open-record.scss'],
  templateUrl: 'open-record.html',
})
export class PopUp {
  constructor(public dialogRef: MatDialogRef<PopUp>) {}

  public textboxValue: string = '';
  public focus: boolean = true;
  public types: string[] = [
    'Inspection',
    'Maintenance',
    'Vibration',
    'Oil Analysis',
  ];
  public products: string[] = ['Okay', 'Warning', 'Serious'];
  public name: string = '';
  ngOnInit(): void {
    this.textboxValue = '';
  }
  isFocused() {
    this.focus = true;
  }
}
