<table class="TableHeader">
  <tr>
    <td>
      <mat-form-field appearance="standard" class="TableHeaderSearch">
        <mat-label  style="color: var(--geaui-vibrant-blue);">Search Assets</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="assetTable.View.Search"
          (ngModelChange)="searchChange($event);"
        />
        <mat-icon style="color:var(--geaui-vibrant-blue);" matSuffix>search</mat-icon>
      </mat-form-field>
    </td>
  </tr>
</table>
<button class="hrt-export-btn"  (click)="exportTable()">
  <span class="csv-text">CSV</span>
  <mat-icon>download</mat-icon>
</button>
<table
  mat-table
  [dataSource]="assetTable.Result.Data"
  matSort
  matSortDisableClear
  [matSortActive]="assetTable.View.SortColumn"
  (matSortChange)="assetTable.RefreshData($event)"
>
  <ng-container matColumnDef="Icon" >
    <th mat-header-cell *matHeaderCellDef class="TableLargeIconColumn"></th>
    <td mat-cell *matCellDef="let row" (click)="openAsset(row.Id)">
      <img
        [src]="getCustomTypeIcon(row.ProductTypeId)"
        [style.max-height.px]="32"
        [style.max-width.px]="getCustomTypeIconWidth(row.ProductTypeId)"
      />
    </td>
  </ng-container>
  <ng-container matColumnDef="AssetTypeId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let row" (click)="openAsset(row.Id)">
      {{ getCustomTypeText(row.ProductTypeId) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="GeaReferenceName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference Name</th>
    <td mat-cell *matCellDef="let row" (click)="openAsset(row.Id)">{{ row.GeaReferenceName }}</td>
  </ng-container>
  <ng-container matColumnDef="Manufacturer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
    <td mat-cell *matCellDef="let row" (click)="openAsset(row.Id)">{{ row.Manufacturer }}</td>
  </ng-container>
  <ng-container matColumnDef="Model">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
    <td mat-cell *matCellDef="let row" (click)="openAsset(row.Id)">{{ row.Model }}</td>
  </ng-container>
  <ng-container matColumnDef="AlternativeModel">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Alt Model</th>
    <td mat-cell *matCellDef="let row" (click)="openAsset(row.Id)">{{ row.AlternativeModel }}</td>
  </ng-container>
  <ng-container matColumnDef="SerialNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
    <td mat-cell *matCellDef="let row" (click)="openAsset(row.Id)">{{ row.SerialNumber }}</td>
  </ng-container>
  <ng-container matColumnDef="SalesOrderNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Number</th>
    <td mat-cell *matCellDef="let row" (click)="openAsset(row.Id)">{{ row.SalesOrderNumber }}</td>
  </ng-container>
  <ng-container matColumnDef="Annunciation">
    <th mat-header-cell *matHeaderCellDef>Annunciation Status</th>
    <td mat-cell *matCellDef="let row" (click)="openRealTimeForAsset(row.Id, row.ConnectionId)">
      <mat-spinner [diameter]="20" *ngIf="row.ConnectionId && !isSocketOpen" style="margin-left: 30px;"></mat-spinner>
      <div class="annun-status" *ngIf="isSocketOpen">
        <img
          [style.display]="row.ConnectionId ? 'block' : 'none'"
          [src]="getAnnunImage(getAnnunStatusValue(row.ConnectionId))"
          class="shutdown-status-img"
        />
        <span *ngIf="row.ConnectionId">{{getAnnunText(getAnnunStatusValue(row.ConnectionId))}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="MotorStatus" *ngIf="showMotorStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Motor Status</th>
    <td mat-cell *matCellDef="let row" (click)="openRealTimeForAsset(row.Id, row.ConnectionId)">
      <mat-spinner [diameter]="20" *ngIf="row.ConnectionId && !isSocketOpen" style="margin-left: 30px;"></mat-spinner>
      <div class="annun-status" *ngIf="isSocketOpen">
        <img
          [style.display]="row.ConnectionId ? 'block' : 'none'"
          [src]="getMotorStatus(row.ConnectionId)"
          class="shutdown-status-img"
        />
        <span *ngIf="getMotorStatus(row.ConnectionId)">{{motorStatusText}}</span>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="assetColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: assetColumns"
    class="TableRowClickable"
    (click)="openRealTimeForAsset(row.Id, row.ConnectionId)"
  ></tr>
</table>
<mat-paginator
  [length]="assetTable.Result.Count"
  [pageSizeOptions]="assetTable.View.PageSizeOptions"
  [pageSize]="assetTable.View.PageSize"
  [pageIndex]="assetTable.View.Page"
  (page)="assetTable.RefreshData($event)"
>
</mat-paginator>
