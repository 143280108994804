import { Component, ElementRef, EventEmitter, Input, NgModule, Output, Renderer2, ViewChild } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { sidebarNavigation } from 'src/app/sidebar-navigation';
import { ButtonV2Module, IconModule } from '@gea/digital-ui-lib';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'hrt-tab',
  templateUrl: './hrt-tab.component.html',
  styleUrls: ['./hrt-tab.component.scss']
})
export class HrtTabComponent {

  public isBookmarked: boolean = false;
  public link: any;

  @Input() title: string;
  @Input() hasTabs: boolean = false;
  @Input() hasShareButton: boolean = false;
  @Input() hasBookmark: boolean = false;
  @Input() tabs: TabData[];
  @Input() renderAllTabs: boolean = false;
  @Input() selectedIndex: number = 0;
  @Output() bookmarkToggled = new EventEmitter<boolean>();

  showBackBtn = true;

  @Input() set bookmarkData(isBookmark: boolean) {
    this.isBookmarked = isBookmark;
  }

  @ViewChild('initialEl') arrowElement: ElementRef;
  @ViewChild('tabGroup', {read: ElementRef}) tabGroup: ElementRef;
  
  constructor(
    private location: Location,
    private router: Router,
    private renderer: Renderer2,
    private clipboard: Clipboard,
    ) {}

  ngOnInit() {
    const currentRoute = this.router.url;
    this.showBackBtn = !(sidebarNavigation.find((nav) => nav.route == currentRoute)?.isInMenu);
  }

  ngAfterViewInit() {
    const width = this.arrowElement.nativeElement.offsetWidth;
    const matHeaderElement = this.tabGroup?.nativeElement.querySelector('.mat-tab-header');
    if (matHeaderElement) {
      this.renderer.setStyle(matHeaderElement, 'left', `${width + 30}px`);
    }
  }

  toggleBookmark(){
    this.bookmarkToggled.emit(this.isBookmarked);
  }

  copyLink(){
    const title = document.title;
    const url = window.location.href;
    console.log(title);
    console.log(url);
    const range = document.createRange();
    this.link = `<a href="${url}">${title} ${"(gea.com)"}</a>`;


    // creating temp div
    const tempDiv = document.createElement('div'); 
    tempDiv.innerHTML = this.link;
    document.body.appendChild(tempDiv);

    // copy the content
    range.selectNode(tempDiv);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand('copy');

    //clean up
    document.body.removeChild(tempDiv);
    window.getSelection()?.removeAllRanges();
  }

  goBack() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/home']);
    }
  }

}

export interface TabData {
  tabName: string,
  tabComponent: any,
  isTabVisible? : boolean;
  data?: any
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonV2Module,
    IconModule,
    MaterialModule
  ],
  declarations: [HrtTabComponent],
  exports: [HrtTabComponent],
})
export class HrtTitleModule {}
