<div class="config-list-container">
  <div class="header">
    <h1 class="header-text">Configurations</h1>
    <button
      mat-raised-button
      class="geaui-button-primary AddButton"
      (click)="addtoConfigList()"
    >
      Add New Config
    </button>
  </div>
  <div class="config-table">
    <hrt-filter-search
      [tableId]="tableId"
      [searchTextPlaceholder]="searchPlaceholder"
      [searchModelField]="configTable.View"
      [hasFilter]="true"
      (searchVal)="searchChange($event)"
      (filters)="applyFilters($event)"
    >
      <table mat-table [dataSource]="configTable.Result.Data">
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="ConfigName">
          <th mat-header-cell *matHeaderCellDef>Config Name</th>
          <td mat-cell *matCellDef="let element"  (click)="openConfigDetails(element)"  >{{ element.ConfigName }}</td>
        </ng-container>

        <ng-container matColumnDef="ConfigValue">
          <th mat-header-cell *matHeaderCellDef>Config Value</th>
          <td mat-cell *matCellDef="let element"(click)="openConfigDetails(element)" >{{ element.ConfigValue }}</td>
        </ng-container>

        <ng-container matColumnDef="IsUIConfig">
          <th mat-header-cell *matHeaderCellDef>Is UI Config?</th>
          <td mat-cell *matCellDef="let element"(click)="openConfigDetails(element)" >{{ element.IsUIConfig ? 'Yes' : 'No' }}</td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef style="width: 10%;">Actions</th>
          <td mat-cell *matCellDef="let element">
            <div class="action-buttons">
              <span (click)="editConfigListItem(element)">
                <mat-icon matSuffix class="ActionIcon" title="Edit"><geaui-icon-v2
                  file="16px_edit"
                  [colorProp]="'stroke'"
                ></geaui-icon-v2></mat-icon>
              </span>
              <span (click)="deleteFromConfigList(element)">
                <mat-icon matSuffix class="ActionIcon" title="Delete"><geaui-icon-v2
                  file="16px_remove"
                  [colorProp]="'stroke'"
                ></geaui-icon-v2></mat-icon>
              </span>
            </div>
            
          </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            style="text-align: center"
            [attr.colspan]="displayedColumns.length"
          >
            No records found
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="configTable.Result.Count"
        [pageSizeOptions]="configTable.View.PageSizeOptions"
        [pageSize]="configTable.View.PageSize"
        [pageIndex]="configTable.View.Page"
        (page)="configTable.RefreshData($event)"
      >
        >
      </mat-paginator>
    </hrt-filter-search>
  </div>
</div>
