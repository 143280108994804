<h1>Add Service Record</h1>
<div class="mat-typography" >
    <mat-tab-group selectedIndex="0">
        <mat-tab label="Record Details">
            <div class="content">
                <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">  
                        <table>
                            <tr>
                                <td>Record Type</td>
                                <div>
                                    <mat-form-field class="textField">
                                        <mat-label>Status</mat-label>
                                            <mat-select>
                                                <mat-optgroup>
                                                    <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
                                                </mat-optgroup>
                                            </mat-select>
                                    </mat-form-field>  
                                </div>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>
                                    <mat-form-field appearance="legacy" class="textField">
                                      <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Serice Order #</td>
                                <mat-form-field appearance="legacy" class="textField">
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>
                            <tr>
                                <td>Site Contact</td>
                                <mat-form-field class="textField"  appearance="legacy">
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>
                            <tr>
                                <td>Service Company</td>
                                <mat-form-field appearance="legacy" class="textField">
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>
                            <tr>
                                <td>Service Technician</td>
                                <mat-form-field  appearance="legacy" class="textField"> 
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>
                            <tr>
                                <td>Project #</td>
                                <mat-form-field appearance="legacy" class="textField">
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>
                            <tr>
                                <td>Serial #</td>
                                <mat-form-field appearance="legacy" class="textField">
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>
                            <tr>
                                <td>Reference Name</td>
                                <mat-form-field  appearance="legacy" class="textField"> 
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>

                            
                        </table> 
                    </div>         
                </div>  
            </div>                
        </mat-tab>
        <!-- <mat-tab label="Compressor">
            <div class="content">
                <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">
                        <table>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><h2 style="margin: 20px">Data Verified</h2></td>
                                <td><h2 style="margin: 20px">Comments</h2></td>
                            
                            </tr>
                            <tr>
                                <td [width]="200">Type</td>
                                <mat-form-field appearance="legacy">
                                    <mat-label>Screw</mat-label>
                                    <input  matInput required [(ngModel)]="name" />
                                </mat-form-field>
                                <td >                                     
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 20px;" value="1">Yes</mat-radio-button>
                                        <mat-radio-button style="padding-left: 15px ; padding-left: 20px;" value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <mat-form-field class="commentsText" appearance="legacy">
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>
                            <tr>
                                <td>Manufacturer</td>
                                <mat-form-field appearance="legacy"> 
                                    <mat-label>GEA Grasso</mat-label>
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                                    <td>                                     
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 15px ; padding-left: 20px;"  value="1">Yes</mat-radio-button>
                                            <mat-radio-button style="padding-left: 15px ; padding-left: 20px;" value="2">No</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <mat-form-field class="commentsText" appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                            </tr>
                            <tr>
                                <td>Model #</td>
                                <mat-form-field appearance="legacy">
                                    <mat-label>W</mat-label>
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                                    <td>                                     
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 15px ; padding-left: 20px;"  value="1">Yes</mat-radio-button>
                                            <mat-radio-button style="padding-left: 15px ; padding-left: 20px;" value="2">No</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <mat-form-field class="commentsText" appearance="legacy"> 
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                            </tr>
                            <tr>
                                <td>Serial #</td>
                                <mat-form-field appearance="legacy">
                                    <mat-label>W0259</mat-label>
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                                <td>                                     
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 20px;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button style="padding-left: 15px ; padding-left: 20px;" value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <mat-form-field class="commentsText" appearance="legacy">
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                            </tr>
                            <tr>
                                <td>Shaft Seal Leak</td>
                                <mat-form-field appearance="legacy">                              
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                                <td>
                                    <div>
                                        <mat-form-field class="commentsText">
                                            <mat-label>Status</mat-label>                                           
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Male Shaft End Float</td>
                                <mat-form-field appearance="legacy">                              
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                                <td>       
                                    <div>
                                    <mat-form-field class="commentsText">
                                        <mat-label>Status</mat-label>
                                        <mat-select>
                                            <mat-optgroup >
                                                <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                            </mat-optgroup>
                                        </mat-select>
                                    </mat-form-field>  
                                </div>
                            </td>                        
                                <td></td>
                            </tr>
                            <tr>
                                <td>Max End Float</td>
                                <mat-form-field appearance="legacy">                         
                                    <input matInput required [(ngModel)]="name" />
                                </mat-form-field>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Motor">
            <div class="content">
                <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">
                        <table>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><h2 style="padding-left: 15px">Date Verified</h2></td>
                                <td><h2 class="commentsText">Comments</h2></td>
                            </tr>
                            <tr>
                                <td>Manufacturer</td>
                                <td></td>
                                <td>
                                    <mat-form-field style="padding-left: 70px" appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 15px;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 15px;" value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <mat-form-field class="commentsText" appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Model #</td>
                                <td></td>
                                <td>
                                    <mat-form-field style="padding-left: 70px"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 15px;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 15px;" value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <mat-form-field class="commentsText" appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Serial #</td>
                                <td></td>
                                <td>
                                    <mat-form-field style="padding-left: 70px"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 15px;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 15px;" value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <mat-form-field class="commentsText" appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Motor Was Realigned</td>
                                <td></td>
                                <td>                                        
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 70px;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button style="padding-right: 15px ; padding-left: 70px;" value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td><h2 style="padding-top: 20px">Power & Current Check</h2></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><h3 style="padding-left: 20px; padding-right: 20px">L1/L2</h3></td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td><h3 style="padding-left: 20px; padding-right: 20px">L1</h3></td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><h3 style="padding-left: 20px; padding-right: 20px">L1/L3</h3></td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td><h3 style="padding-left: 20px; padding-right: 20px">L2</h3></td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><h3 style="padding-left: 20px; padding-right: 20px">L2/L3</h3></td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td><h3 style="padding-left: 20px; padding-right: 20px">L1</h3></td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Oil Seperator">
            <div class="content">
                <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">
                        <table>
                            <div  style="height: 500px;">
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <h2 style="padding-left: 15px">Data Verified</h2>                                   
                                    <td>
                                        <h2 style="padding-left: 20px">Comments</h2>              
                                    </td>
                                </tr>
                                <tr>
                                    <td>Manufacturer</td>
                                    <td>
                                        <mat-form-field class="commentsText" appearance="legacy">
                                            <input matInput required [(ngModel)]="name" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 15px ; padding-left: 15px;"  value="1">Yes</mat-radio-button>
                                            <mat-radio-button style="padding-right: 15px ; padding-left: 15px;" value="2">No</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <td>
                                        <mat-form-field class="commentsText" appearance="legacy">
                                            <input matInput required [(ngModel)]="name" />
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>National Board #</td>
                                    <td>
                                        <mat-form-field class="commentsText"  appearance="legacy">
                                            <input matInput required [(ngModel)]="name" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 15px ; padding-left: 15px;"  value="1">Yes</mat-radio-button>
                                            <mat-radio-button style="padding-right: 15px ; padding-left: 15px;" value="2">No</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <td>
                                        <mat-form-field class="commentsText"  appearance="legacy">
                                            <input matInput required [(ngModel)]="name" />
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Serial #</td>
                                    <td>
                                        <mat-form-field class="commentsText"  appearance="legacy">
                                            <input matInput required [(ngModel)]="name" />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 15px ; padding-left: 15px;"  value="1">Yes</mat-radio-button>
                                            <mat-radio-button style="padding-right: 15px ; padding-left: 15px;" value="2">No</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <td>
                                        <mat-form-field class="commentsText"  appearance="legacy">
                                            <input matInput required [(ngModel)]="name" />
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Primary Upper Sight Glass</td>
                                    <td>
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 5px ; padding-left: 15px; padding-top: 20px"  value="1">100%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 5px;" value="2">50%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 5px;" value="2">0%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 15px;" value="3">N/A</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Primary Center Sight Glass</td>
                                    <td>
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 5px ; padding-left: 15px; padding-top: 20px"  value="1">100%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 5px;" value="2">50%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 5px;" value="2">0%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 15px;" value="3">N/A</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Primary Lower Sight Glass</td>
                                    <td>
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 5px ; padding-left: 15px; padding-top: 20px"  value="1">100%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 5px;" value="2">50%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 5px;" value="2">0%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 15px;" value="3">N/A</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Secondary Sight Glass</td>
                                    <td>
                                        <mat-radio-group>
                                            <mat-radio-button style="padding-right: 5px ; padding-left: 15px; padding-top: 20px"  value="1">100%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 5px;" value="2">50%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 5px;" value="2">0%</mat-radio-button>
                                            <mat-radio-button style="padding-left: 5px ; padding-right: 15px;" value="3">N/A</mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </div>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Control Panel">
            <div class="content">
                <div fxLayout="row wrap" fxLayoutGap="25px grid">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">
                        <table>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><h2 class="commentsText">Data Verified</h2></td>
                                <td><h2 class="commentsText" >Comments</h2></td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td>
                                    <mat-form-field style="padding-right: 50px ; padding-left: 15px;"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button class="doublePadding" value="1">Yes</mat-radio-button>
                                        <mat-radio-button class="doublePadding"  value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <mat-form-field class="commentsText"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Manufacturer</td>
                                <td>
                                    <mat-form-field style="padding-right: 15px ; padding-left: 15px;" appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button class="doublePadding"   value="1">Yes</mat-radio-button>
                                        <mat-radio-button class="doublePadding" value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <mat-form-field class="commentsText"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Model #</td>
                                <td>
                                    <mat-form-field class="doublePadding"   appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button class="doublePadding"   value="1">Yes</mat-radio-button>
                                        <mat-radio-button class="doublePadding"  value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <mat-form-field class="commentsText"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Serial #</td>
                                <td>
                                    <mat-form-field class="doublePadding"   appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button class="doublePadding"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button class="doublePadding"  value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <mat-form-field class="commentsText"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td>
                                    <h2>Operating Data</h2>
                                </td>
                                <td></td>
                                <td></td>
                                <td><button mat-stroked-button>Populate Operating Data From IoT</button></td>
                            </tr>
                            <tr>
                                <td>Runtime Hours</td>
                                <td>
                                    <mat-form-field class="col-3" class="doublePadding"   appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Suction Temperature</td>
                                <td>
                                    <mat-form-field class="doublePadding"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>Suction Pressure</td>
                                <td>
                                    <mat-form-field class="doublePadding"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Discharge Temperature</td>
                                <td>
                                    <mat-form-field class="doublePadding"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>Discharge Pressure</td>
                                <td>
                                    <mat-form-field class="doublePadding"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Inlet Oil Temperature</td>
                                <td>
                                    <mat-form-field class="doublePadding"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                                <td>Inlet Oil Pressure</td>
                                <td>
                                    <mat-form-field class="doublePadding"  appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="ANSI / IIAR 6 Inspection Items" >
            <div class="content">
                <table>
                    <div  style="height: 600px;">
                            <tr>
                                <td></td>
                                <td><h2>Conforms</h2></td>
                                <td><h2>Status</h2></td>
                                <td><h2>Comments</h2></td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Discharge Pressure Safety Cutout Operating Correctly</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>                                    
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                         
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">High Pressure, Low Pressure, Oil Pressure Control Devices Present & Functioning Adequately</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                       
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Equipment Labeled & Nameplate Legible Per ANSI / IIAR 2</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                        
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Operating Within Limits</td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                        
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Fasteners Tight, Adequately Anchored & Supported</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                        
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Safe Access For Inspection, Maintenance & Testing (ITM)</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                        
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Free of Excessive Ice Buildup</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                       
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Free of Abnormal Sounds & Vibration</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                       
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Free of Refrigerant Leaks</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                       
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Piping Has Markers Per ANSI / IIAR 2</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                       
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Valves In Good Condition</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                     
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Control Valves Tagged, Exercised & Stems Lubricated</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                       
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Pressure/Temperature Gauges and/or Transducers Present & Functioning Adequately</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                        
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Coupling In Good Working Condition & Adequately Gaurded</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                        
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Free Of Modifications, Alterations, Damage or Repairs Such That Compressor Casing Integrity Is or Has Been Affected</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                        
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Compressor Has Suction & Discharge Stop Valves & Discharge Check Valve</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                       
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Free of Pitting & Surface Damage</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                       
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><p class="mat-body-1" style="width: 400px">Free of Other Conditions That Negatively Affect Safe Operation</p></td>
                                <td>
                                    <mat-radio-group>
                                        <mat-radio-button style="padding-right: 5px ;"  value="1">Yes</mat-radio-button>
                                        <mat-radio-button  value="2">No</mat-radio-button>
                                        <mat-radio-button style="padding-left: 5px ;" value="3">N/A</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <div>
                                        <mat-form-field class="smallDoublePadding">                                      
                                                <mat-select>
                                                    <mat-optgroup>
                                                        <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>             
                                        </mat-form-field>  
                                    </div>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <input matInput required [(ngModel)]="name" />
                                    </mat-form-field>
                                </td>
                            </tr>
                    </div>
                </table>
            </div>
        </mat-tab> -->
    </mat-tab-group> 
</div>


