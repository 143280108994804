<dx-popup [width]="1000" [height]="700" [showTitle]="false" title="Find Asset" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="Show" (visibleChange)="visibleChanged($event)">
    <div *dxTemplate="let data of 'content'">
        <table class="TableHeader">
            <tr>
                <td>
                    <mat-icon matSuffix class="TableHeaderIcon">scatter_plot</mat-icon>
                </td>
                <td>
                    <span class="TableHeaderText">Assets</span>
                </td>
                <td class="TableHeaderSearch">
                    <mat-form-field appearance="standard" class="TableHeaderSearchBox">
                        <mat-label>Search Assets</mat-label>
                        <input type="text" matInput [(ngModel)]="table.View.Search" (ngModelChange)="table.SearchUpdated()">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>
        </table>
        <table mat-table [dataSource]="table.Result.Data" matSort matSortDisableClear [matSortActive]="table.View.SortColumn" (matSortChange)="table.RefreshData($event)">
            <ng-container matColumnDef="ReferenceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference Name</th>
                <td mat-cell *matCellDef="let row">{{row.GeaReferenceName}}</td>
            </ng-container>
            <ng-container matColumnDef="Manufacturer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                <td mat-cell *matCellDef="let row">{{row.Manufacturer}}</td>
            </ng-container>
            <ng-container matColumnDef="Model">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
                <td mat-cell *matCellDef="let row">{{row.Model}}</td>
            </ng-container>
            <ng-container matColumnDef="AlternativeModel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Alt Model</th>
                <td mat-cell *matCellDef="let row">{{row.AlternativeModel}}</td>
            </ng-container>
            <ng-container matColumnDef="SerialNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
                <td mat-cell *matCellDef="let row">{{row.SerialNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="ProjectNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Number</th>
                <td mat-cell *matCellDef="let row">{{row.SalesOrderNumber}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="TableRowClickable" (click)="selectAsset(row)"></tr>
        </table>
    </div>
</dx-popup>