@if (isEnvironment == 'local') {
  <hrt-tab
    [hasTabs]="true"
    [title]="'Assets'"
    [tabs]="assetTabs"
    [renderAllTabs]="false"
  ></hrt-tab>
} @else {
  <hrt-tab [hasTabs]="false" [title]="'Assets'"></hrt-tab>
  <gea-asset-table [assetTable]="table" [showMotorStatus]="true"></gea-asset-table>
}
