import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { LoaderService } from '../loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { inject } from '@angular/core';
import {
  Dropdown,
  GetFilterConfigKey,
  IsClientSideFilter,
} from '../../data/filterConfiguration';
import { UtilityService } from '../utility.service';
import { FilterService } from '../filter.service';

export class TableResult<T> {
  public Count: number = 0;
  public Data: T[] = [];
}

export class TableView {
  public Search: string = '';
  public SortColumn: string = '';
  public SortDesc: string = 'False';
  public PageSizeOptions: number[] = [10, 20, 50, 100, 500, 1000];
  public PageSize: number | undefined = 20;
  public Page: number | undefined = 0;
  public HasPaging: boolean = true;
  public Filters: Filter = {};
  public ClientFilters: string[] = [];
  public ServerFilters: string[] = [];
}

export class Filter {
  [key: string]: {
    isClientSide?: boolean;
    type?: string;
    values: Dropdown[];
  };
}

export class Table<T> {
  constructor(private loader?: LoaderService) {}

  public Result: TableResult<T> = new TableResult<T>();
  public ResultCopy: TableResult<T> = new TableResult<T>();
  public View: TableView = new TableView();
  public EditRow: T | undefined = undefined;
  public IsNew: boolean = false;
  public inputSubject = new BehaviorSubject<string>('');
  private snackbar = inject(MatSnackBar);
  private utilityService = inject(UtilityService);
  private filterService = inject(FilterService);
  public oldRequest: any = {};
  private filterDelayed: any = undefined;

  public Updated: () => void = () => {};

  public GetApi:
    | ((listOptions: TableView) => Observable<TableResult<T>>)
    | undefined;
  // Update, New, Delete

  public UpdateResult(result: TableResult<T>) {
    this.Result = result;
    this.Updated();
  }

  public UpdateResultCopy(result: TableResult<T>) {
    this.ResultCopy = result;
    //this.Updated();
  }

  public SearchUpdated() {
    clearTimeout(this.filterDelayed);
    this.filterDelayed = setTimeout(() => {
      this.RefreshData();
    }, 0);
    // this.refreshDataForTable()?.subscribe((val) => {});
  }

  /**
   * Add filter to the View object.
   * @param filterKey 
   * @param filterValue 
   * @param filterConfig 
   * @param filterType 
   */
  addToFilter(
    filterKey: string,
    filterValue: Dropdown[],
    filterConfig: string,
    filterType?: string
  ) {
    let currentFilter = this.View.Filters[filterKey];
    const isClientSide: boolean = IsClientSideFilter(filterConfig) ?? false;
    if (currentFilter) {
      // apend to values of current filter - never called.
      this.View.Filters[filterKey].isClientSide = isClientSide;
      this.View.Filters[filterKey].type = filterType ?? undefined;
      this.View.Filters[filterKey].values = [
        ...currentFilter.values,
        ...filterValue,
      ];
    } else {
      this.View.Filters[filterKey] = {
        isClientSide: isClientSide ?? undefined,
        type: filterType ?? undefined,
        values: filterValue,
      };
    }
    (isClientSide) ? this.View.ClientFilters.push(filterConfig) : this.View.ServerFilters.push(filterConfig);
  }

  /**
   * Applies the client side filters.
   * @returns 
   */
  async applyClientSideFilters() {
    if (this.View.ClientFilters.length <= 0) return;

    this.loader?.showLoadingIcon('Waiting for socket connection');
    const updatedResult: TableResult<T> = JSON.parse(
      JSON.stringify(this.ResultCopy)
    );

    for (const filterConfig of this.View.ClientFilters) {
      const filterKey = GetFilterConfigKey(filterConfig);
      const keysToMatch = this.View.Filters[filterKey].values.map(
        (item) => item.key
      );

      const results = await Promise.all(
        updatedResult.Data.map(async (data: any) => {
          const res = await this.filterService.filterFuncMap[filterConfig](
            data.ConnectionId
          );
          return { data, matches: keysToMatch.includes(res) };
        })
      );
      const filteredData = results
        .filter((result) => result.matches)
        .map((result) => result.data);

      updatedResult.Data = filteredData;
      updatedResult.Count = filteredData.length;
      this.UpdateResult(updatedResult);
      this.loader?.hideLoadingIcon();
    }
  }

  clearFilterLists() {
    this.View.ClientFilters = [];
    this.View.ServerFilters = [];
  }

  /**
   * Refresh table data.
   * @param event 
   */
  public RefreshData(event?: PageEvent | Sort) {
    var pageEvent = event as PageEvent;
    if (pageEvent) {
      this.View.Page = pageEvent.pageIndex;
      if (pageEvent.pageSize) this.View.PageSize = pageEvent.pageSize;
      if (this.View.Page === undefined) this.View.Page = 0;
    }
    var sort = event as Sort;
    if (sort && sort.active) {
      this.View.SortColumn = sort.active;
      this.View.SortDesc = sort.direction == 'desc' ? 'True' : 'False';
    }
    if (
      this.GetApi &&
      !this.utilityService.deepEqual(this.oldRequest, this.View)
    ) {
      this.loader?.showLoadingIcon();
      const onlyClient = this.checkForClientFilters();
      if (onlyClient) {
        this.applyClientSideFilters()
          .then(() => {})
          .catch((error: Error) => {
            console.error('Error applying client-side filters:', error.message);
          });
      } else {
        this.GetApi(this.View).subscribe({
          next: (data: TableResult<T>) => {
            this.UpdateResult(data);
            this.UpdateResultCopy(data);
            this.loader?.hideLoadingIcon();
            if (data.Count > 0) {
              this.applyClientSideFilters()
                .then(() => {})
                .catch((error: Error) => {
                  console.error(
                    'Error applying client-side filters:',
                    error.message
                  );
                });
            }
          },
          error: (err: any) => {
            this.snackbar.open(
              'Failed to fetch data. Error: ' + err.messageStr,
              'X',
              {
                duration: 3000,
                panelClass: ['notify-error'],
                verticalPosition: 'top',
              }
            );
            this.loader?.hideLoadingIcon();
          },
        });
      }
    }
  }

  /**
   * Checks if only client side filter is applied.
   * @returns 
   */
  checkForClientFilters() {
    const diffKeys = this.utilityService.deepCompare(
      this.oldRequest,
      this.View
    );
    this.oldRequest = JSON.parse(JSON.stringify(this.View));

    const viewKeys = Object.keys(this.View).filter(x => x != 'ClientFilters');
    const containsClientFilters =
      this.View.ClientFilters.length > 0 &&
      diffKeys?.some((key) => key.includes('ClientFilters'));
    const doesNotContainServerFilters = diffKeys?.every(
      (key) => viewKeys.forEach(viewKey => !key.includes(viewKey))
    );

    return containsClientFilters && doesNotContainServerFilters;
  }

  refreshDataForTable(): Observable<TableResult<T>> | undefined {
    if (this.GetApi) {
      return this.GetApi(this.View);
    }
    return undefined;
  }
}
